import React from "react"
import clsx from "clsx"

import Container from "@ecom/ui/components/Container"
import Button from "@material-ui/core/Button"
import Img from "./Img"
import ImgLabel from "./ImgLabel"

import * as styles from "./banner.module.scss"
import { useObserver } from "../../../hooks/useObserver"
import { handleClickBanner } from "../../../helpers/WatcherDL/utils/handleClickBanner"

type BannerProps = {
  buttonText?: string
  title?: string
  description?: string
  orderNum?: string
}

const defaultTitle = `
  <span>Кэшбэк 1000 баллов 
  <br/>
  на 1-ую покупку</span>
`

const defaultDescription = `
  — Кэшбэк 1000 баллов на 1-ую покупку
  <br />
  — Кэшбэк до 10%
  <br />
  — Обслуживание 0 ₽
`

export function Banner({
  buttonText = "Оформить карту",
  title = defaultTitle,
  description = defaultDescription,
  orderNum,
}: BannerProps) {
  const isVisible = useObserver(["app", "calculatorCard", "reviewCard", "moreBenefits", "formPkw"])
  return (
    <>
      <section data-exclude={orderNum}>
        <Container className={styles.container}>
          <div className={styles.secondContainer}>
            <Img alt="bg image" className={styles.img} />
            <ImgLabel alt="zoon image" className={styles.imgLabel} />
            <div className={styles.text}>
              <h1 dangerouslySetInnerHTML={{ __html: title }} />
              <div dangerouslySetInnerHTML={{ __html: description }} />
            </div>
            <Button className={styles.btn} onClick={() => handleClickBanner("click_cta")}>
              {buttonText}
            </Button>
          </div>
        </Container>
      </section>
      <div className={clsx(styles.btnBlock, { [styles.hideMobBlock]: isVisible })}>
        <Container>
          <Button onClick={() => handleClickBanner("sticky")} className={styles.mobBtn}>
            {buttonText}
          </Button>
        </Container>
      </div>
    </>
  )
}
